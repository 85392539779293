































import shave from 'shave'
import { Component, Prop, Vue } from 'vue-property-decorator'

import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import Tag from '@/components/_uikit/Tag.vue'
import {
  ExerciseAccessLevel,
  ExerciseStatus,
  JournalLargeExerciseResource,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonIconAction,
    Tag,
  },
})
export default class MasterExerciseCard extends Vue {
  @Prop({ required: true })
  private exercise!: JournalLargeExerciseResource

  @Prop({ default: null })
  private userID!: number

  private get redirect() {
    return {
      name: 'manager.education.exercises.item.task.master.answer',
      params: {
        exerciseUUID: this.exercise.uuid,
        groupID: this.$route.query.groupID,
        masterID: this.userID.toString(),
        taskUUID: this.exercise.tasks[0].uuid,
      },
    }
  }

  private get tagColor () {
    if (this.exercise.status.value === ExerciseStatus.COMPLETE || this.exercise.status.value === ExerciseStatus.CHECKED) {
      return 'teal'
    }

    else if (this.exercise.isDeadlinePassed) {
      return 'red'
    }

    return 'orange'
  }

  private get textColor () {
    if (this.exercise.status.value === ExerciseStatus.COMPLETE || this.exercise.status.value === ExerciseStatus.CHECKED) {
      return 'teal'
    } else {
      return 'orange'
    }
  }

  private get isPersonal () {
    return this.exercise.accessLevel.value === ExerciseAccessLevel.PERSONAL
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted () {
    shave(`#exercise-title-${this.exercise.id}`, 48)
  }
}
