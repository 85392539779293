













































import { MetaInfo } from 'vue-meta'
import { Component, Watch } from 'vue-property-decorator'

// Store
import DictionaryModule from '@/store/modules/dictionary'
import ManagerUsersModule from '@/store/modules/manager/users'
import ManagerGroupsModule from '@/store/modules/manager/groups'
// Mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// Components
import MasterExerciseCard from '@/components/cards/MasterExerciseCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
// Helpers
import { formatDate, getCurrentMonth } from '@/utils/functions'
// Types
import { GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IMasterExercisesFilter,
  IMasterExercisesFilterType,
  JournalLargeExerciseResource,
} from '@/store/types'

@Component({
  components: {
    MasterExerciseCard,
    Select,
  },
})
export default class MastersItemExercises extends NotifyMixin {
  private mastersExercises: JournalLargeExerciseResource[] = []
  private filter: IMasterExercisesFilter = GET_DEFAULT_TABLE_FILTER()
  private currentMasterGroupID = +this.$route.query.groupID

  private get filterString () {
    return JSON.stringify(this.filter)
  }

  private get userId () {
    return ManagerUsersModule.user?.id
  }

  private get masterGroups () {
    return DictionaryModule.masterGroupsByUserId.map(item => ({ name: item.title, value: item.id }))
  }

  private get months() {
    if (DictionaryModule.masterGroupsByUserId) {
      const foundGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
      if (foundGroup) {
        return foundGroup.months.map((item) => ({ ...item, name: formatDate(item.name, 'yyyy LLLL') }))
      }
    }
    return []
  }

  private get achivedPoints () {
    return this.mastersExercises.reduce((acc: number, cur: JournalLargeExerciseResource) => acc += cur.points, 0)
  }

  private get overallPoints () {
    return this.mastersExercises.reduce((acc: number, cur: JournalLargeExerciseResource) => acc += cur.maxPoints, 0)
  }

  private get exerciseStatuses () {
    return DictionaryModule.exerciseStatusesFilter
  }

  private mounted () {
    this.$bus.$on('updateMasterExercises', this.fetchGroupMasterExercises)

    DictionaryModule.fetchMasterGroupByMasterId(this.userId as number)
      .then(() => {
        const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
        if (foundMasterGroup) {
          const currentMonth = getCurrentMonth(foundMasterGroup?.months)
          if (currentMonth) {
            this.filter = {
              ...this.filter,
              monthId: currentMonth.value as number,
            }
          }
        }
      })
      .catch(this.notifyError)
  }

  private destroyed() {
    this.$bus.$off('updateMasterExercises', this.fetchGroupMasterExercises as any)
  }

  private handleFilter (field: IMasterExercisesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private fetchGroupMasterExercises () {
    ManagerGroupsModule.fetchGroupMasterExercises({ filter: this.filter, masterGroupID: this.currentMasterGroupID, userId: this.userId as number })
      .then(response => {
        this.mastersExercises = response
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Домашняя работа',
    }
  }

  @Watch('filterString')
  private watchFilter () {
    this.fetchGroupMasterExercises()
  }

  @Watch('currentMasterGroupID')
  private watchCurrentMasterGroupId (value: number) {
    const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
    if (foundMasterGroup) {
      const currentMonth = getCurrentMonth(foundMasterGroup?.months)
      if (currentMonth) {
        this.filter = {
          ...this.filter,
          monthId: currentMonth.value as number,
        }
      }
      this.$bus.$emit('updateCurrentMasterGroup', value)
    }
  }

  @Watch('filter.monthId')
  private watchFilterMonth(value: number, previosValue?: number) {
    const foundMasterGroup = DictionaryModule.masterGroupsByUserId.find(item => item.id === this.currentMasterGroupID)
    if (foundMasterGroup) {
      const currentMonth = getCurrentMonth(foundMasterGroup?.months)
      if (value !== currentMonth?.value || previosValue) {
        this.$bus.$emit('changeMonth', value)
      }
    }
  }
}
